import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../../context/Context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment-timezone";
import EventRow from "./EventRow";
import AddItemFormRouting from "../Database/AddForms/ItemAddFormRouting";
import schemas from "../Database/schemas.json"; // Adjust path as necessary
import StadiumIcon from "@mui/icons-material/Stadium";
import SportsIcon from "@mui/icons-material/Sports";
import { useTheme } from "@mui/material/styles";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

export default function EventsTable() {
  const { state } = useContext(MyContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("start_time");
  const [addItemOpen, setAddItemOpen] = useState(false);
  const [selectedTournament, setSelectedTournament] =
    useState("All Tournaments");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [paginatedEvents, setPaginatedEvents] = useState([]);
  const currentSchema = schemas.tables["events"]?.item_schema;
  const theme = useTheme();
  const [addItemType, setAddItemType] = useState("event");

  useEffect(() => {
    // Filter and sort the events when the component mounts or when state changes
    let events = state.database.events || [];

    if (selectedTournament !== "All Tournaments") {
      events = events.filter(
        (event) => event.tournament_uid === selectedTournament
      );
      setPage(0);
    }

    let sorted_events = sortEvents(events);
    const startIndex = page * rowsPerPage;
    const paginated = sorted_events.slice(startIndex, startIndex + rowsPerPage);
    setPaginatedEvents(paginated);
    setFilteredEvents(sorted_events);
  }, [
    state.database.events,
    orderBy,
    orderDirection,
    selectedTournament,
    filteredEvents,
    page,
    rowsPerPage,
  ]);

  const handleAddItemOpen = (type) => {
    setAddItemType(type); // Sets whether it's for "event" or "tournament"
    setAddItemOpen(true);
  };

  const handleAddItemClose = () => {
    setAddItemOpen(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortEvents = (array) => {
    return array.sort((a, b) => {
      return (
        (orderDirection === "asc" ? 1 : -1) * (a[orderBy] < b[orderBy] ? -1 : 1)
      );
    });
  };

  const isEventPast = (endTime) => {
    const now = moment();
    const endMoment = moment(Number(endTime));
    return now.isAfter(endMoment);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset page to zero when rows per page changes
  };

  const handleTournamentChange = (event) => {
    setSelectedTournament(event.target.value);
  };

  // Function to format values
  const formatValue = (key, value) => {
    const tableName = "events";
    if (schemas.tables[tableName]?.item_schema[key]?.tableReference) {
      const referencedTable =
        schemas.tables[tableName]?.item_schema[key].tableReference;
      const referencedItem = state.database[referencedTable].find(
        (item) => item.uid === value
      );
      return referencedItem ? referencedItem.alias : value;
    }
    return value;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "0px",
          gap: "40px",
        }}
      >
        <FormControl
          variant="outlined"
          sx={{
            minWidth: "250px",
            marginLeft: "20px",
            height: "36px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px 5px 0px 0px",
              outline: "none",
              backgroundColor: (theme) => theme.palette.secondary.main, // Background color
              color: (theme) => theme.palette.secondary.contrastText, // Text color
              "& .MuiSelect-select": {
                color: (theme) => theme.palette.secondary.contrastText, // Ensure text in Select matches
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", // Remove border color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", // Remove border color on focus
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: "none",
              borderColor: "transparent", // Ensures no visible border on focus or initial render
            },
            "& .MuiSelect-icon": {
              color: (theme) => theme.palette.primary.contrastText, // Set arrow icon color to match text
            },
          }}
        >
          <Select
            labelId="tournament-select-label"
            id="tournament-select"
            value={selectedTournament}
            renderValue={(value) => (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <StadiumIcon /> {/* Adjust icon color if needed */}
                <span>{formatValue("tournament_uid", value)}</span>
              </div>
            )}
            onChange={handleTournamentChange}
            style={{
              height: "100%", // Ensures full height alignment
              padding: "0 12px", // Padding to align content
            }}
          >
            <MenuItem value="All Tournaments">All Tournaments</MenuItem>
            {state.database.tournaments.map((tournament) => (
              <MenuItem key={tournament.uid} value={tournament.uid}>
                {formatValue("tournament_uid", tournament.uid)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={() => handleAddItemOpen("event")} // Opens form for adding an event
          variant="contained"
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            minWidth: "250px",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
          }}
        >
          <SportsIcon style={{ marginRight: "5px" }} /> Add Event
        </Button>
        <Button
          onClick={() => handleAddItemOpen("tournament")} // Opens form for adding a tournament
          variant="contained"
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            minWidth: "250px",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
          }}
        >
          <StadiumIcon style={{ marginRight: "5px" }} /> Add Tournament
        </Button>
        <Button
          onClick={() => {}}
          disabled={true}
          variant="contained"
          style={{
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            minWidth: "250px",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.15)",
          }}
        >
          <AutoFixHighIcon style={{ marginRight: "5px" }} /> Workflow Wizard
        </Button>
      </div>
      <AddItemFormRouting
        open={addItemOpen}
        onClose={handleAddItemClose}
        schema={
          addItemType === "event"
            ? currentSchema
            : schemas.tables["tournaments"].item_schema
        }
        tableName={addItemType === "event" ? "events" : "tournaments"}
      />
      <TableContainer component={Paper}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEvents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table>
          <TableHead style={{ borderBottom: "1px solid grey" }}>
            <TableRow>
              {[
                { id: "expand", label: "" },
                { id: "uid", label: "UID" },
                { id: "alias", label: "Name" },
                { id: "home_team", label: "Home" },
                { id: "away_team", label: "Away" },
                { id: "start_time", label: "Start" },
                { id: "end_time", label: "End" },
                { id: "number", label: "Number" },
                { id: "stage", label: "Stage" },
                { id: "tournament_uid", label: "Tournament" },
                { id: "workflow_profile_uid", label: "Workflow" },
                { id: "distribution_uid", label: "Distribution" },
                { id: "config built", label: "Config" },
              ].map((headCell) => (
                <TableCell key={headCell.id}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? orderDirection : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedEvents.map((event) => (
              <EventRow
                event={event}
                key={event.uid}
                isPast={isEventPast(event.end_time)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
